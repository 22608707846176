import React from "react";
import { cn } from "@bem-react/classname";
import "./CloZIcon.scss";

export const cnCloZIcon = cn("CloZIcon");

export const CloZIcon = ({ className }) => {
  return (
    <div className={cnCloZIcon(null, [className])} />
  );
};
