import React from 'react';
import { cn } from '@bem-react/classname';
import copy from 'copy-to-clipboard';

import './CopyWrapper.scss';

const copyText = text => () => copy(text);

export const cnCopyWrapper = cn('CopyWrapper');

export const CopyWrapper = ({ children, text }) => (
    <div className={cnCopyWrapper()}>
        <span className={cnCopyWrapper('Text')}>{children}</span>
        <i className={cnCopyWrapper('Icon', ['fas', 'fa-clone'])} onClick={copyText(text)}></i>
    </div>
);
