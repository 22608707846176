import React from 'react';
import { cn } from '@bem-react/classname';
import { CopyWrapper } from '../CopyWrapper/CopyWrapper';
import avatarSrc from '../../../../public/assets/me.jpg';

import './Avatar.scss';

export const cnAvatar = cn('Avatar');

export const Avatar = ({
  email,
  location,
  phone,
  profession,
  username,
}) => {
  return (
    <div className={cnAvatar()}>
      <div className={cnAvatar('Image')}>
        <img src={avatarSrc} />
      </div>
      <div className={cnAvatar('UserInfo')}>
        <h1 className={cnAvatar('Username')}>
          <CopyWrapper text={username}>
            {username}
          </CopyWrapper>
        </h1>
        <br />
        <p className={cnAvatar('Contact')}>
          <CopyWrapper text={profession}>{profession}</CopyWrapper>
        </p>
        <p className={cnAvatar('Contact')}>
          <CopyWrapper text={email}>{email}</CopyWrapper>,
          <CopyWrapper text={phone}>{phone}</CopyWrapper>
        </p>
        <p className={cnAvatar('Contact')}>
          <CopyWrapper text={location}>{location}</CopyWrapper>
        </p>
      </div>
    </div>
  );
};
