import React from 'react';
import { cn } from '@bem-react/classname';

import './Socials.scss';

export const cnSocials = cn('Socials');

export const Socials = () => {
  return (
    <div className={cnSocials()}>
      <a className={cnSocials('Item')} href='https://t.me/mfpjke' target='_blank' rel='noopener noreferrer'>
        <i className='fas fa-paper-plane'></i>
      </a>
      <a
        className={cnSocials('Item')}
        href='https://www.linkedin.com/in/вадим-малютин-5386a5122/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <i className='fab fa-linkedin-in'></i>
      </a>
      <a
        className={cnSocials('Item')}
        href='https://www.instagram.com/mfpjke/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <i className='fab fa-instagram'></i>
      </a>
      <a className={cnSocials('Item')} href='https://github.com/mdusafp' target='_blank' rel='noopener noreferrer'>
        <i className='fab fa-github-alt'></i>
      </a>
      <a className={cnSocials('Item')} href='mailto:mdusafp@gmail.com'>
        <i className='fas fa-envelope'></i>
      </a>
    </div>
  );
};
