import reactForBeginnerCertSrc from "../../public/assets/images/certs/react-for-beginner-master-package.png";
import learnNodeCertSrc from "../../public/assets/images/certs/learn-node-master-package.png";
import awsCertifiedDeveloperCertSrc from "../../public/assets/images/certs/aws-certified-developer-associate.pdf";

export const IconTypes = {
  aws: "aws",
  cloz: "cloz",
  eyehelper: "eyehelper",
  freelance: "freelance",
  griddynamics: "griddynamics",
  holyjs: "holyjs",
  stepik: "stepik",
  upteam: "upteam",
  vectorway: "vectorway",
  wesbos: "wesbos",
  yandex: "yandex"
};

export const data = {
  contacts: {
    email: "mdusafp@gmail.com",
    location: "Saint-Petersburg, Russia",
    phone: "+79811446427",
    profession: "Product / Web Developer",
    username: "Malyutin Vadim",
  },
  summary: `
        My approach is product and development at the same time.
        I love to test, launch, validate hypotheses, dive into business context to find user problems and fix them.
        I’ve the makings of a speaker and leader.
    `,
  experience: [
    {
      iconType: IconTypes.griddynamics,
      company: "Grid Dynamics",
      actions: [
        "specialization lead with 6 mentee",
        "splitted monolith system into micro-services & micro-frontend approach",
        "performed at the internal knowledge sharing sessions",
        "wrote few AWS Lambda functions in China region & help to debug infra there",
        "prepared & conducted demo's, spikes, and investigation",
        "launched internal tool as Tech Lead / manager to Beta version",
      ],
      period: "December 2020 – Present",
      location: "Saint-Petersburg, Russia",
    },
    {
      iconType: IconTypes.yandex,
      company: "Yandex",
      actions: [
        "worked in search, main page, UGC and Webmaster teams at the bootcamp",
        "decided to stay in Webmaster and focus on SEO problems to help webmasters",
        "start migration from BEM to React",
        "performed at the internal demo's",
        "made integrations with related projects Turbo, Metrika",
        "goals to achieve – growth of WAU and Metrika counter bindings",
        "built internal project for ML developers with ReactJS, TS, NextJS, MaterialUI, Redux",
      ],
      period: "October 2019 – December 2020",
      location: "Saint-Petersburg, Russia",
    },
    {
      iconType: IconTypes.upteam,
      company: "UpTeam (ex Bekitzur)",
      actions: [
        "developed 8 applications in 5 different teams",
        "organized local meetups, code competitions and events",
        "code as backend, frontend, fullstack and mobile dev",
        "produce 2 MVP to test Flutter",
        "lead people in one project",
        "built micro service architecture with Kafka",
        "maintain browser plugin",
        "grab data for hypothesis testing",
        "built cli for automation purposes",
      ],
      period: "June 2017 – September 2019",
      location: "Saint-Petersburg, Russia",
    },
    {
      iconType: IconTypes.freelance,
      company: "Freelance",
      actions: [
        "built landing pages for PR-festival in “LETI”, WATTY Games, rocont.ru, soft.rocont.ru and so on",
        "built e-commerce project based on Opencart (lumistar.ru)",
        "maintain content projects with WordPress",
      ],
      period: "January 2016 - January – 2017",
      location: "Saint-Petersburg, Russia",
    },
  ],
  sideActivities: [
    {
      iconType: IconTypes.cloz,
      company: "CLO-Z",
      actions: [
        "co-founder of first real-time virtual try-on technology for clothes in browser. The tech helps brands reduce apparel returns and increase sales conversion. It works across Web(mobile/desktop), iOS, Android, and can be integrated into apps and websites in a few days."
      ],
      period: "June 2021 – Present",
      location: "Saint-Petersburg, Russia",
    },
    {
      iconType: IconTypes.holyjs,
      company: "HolyJS",
      actions: [
        "performed at autumn 2021 HolyJS Moscow conference with talk 'Как готовить production с Webpack 5 module federation'",
        "https://holyjs-moscow.ru/talks/how-to-make-a-production-with-webpack-5-module-federation/"
      ]
    },
    {
      iconType: IconTypes.eyehelper,
      company: "Eye Helper",
      actions: [
        "developed with 2 friends free to use application for eye training based on Flutter technology",
        "https://pages.flycricket.io/eye-helper"
      ],
      period: "September 2019 – August 2020",
      location: "Saint-Petersburg, Russia",
    },
    {
      iconType: IconTypes.vectorway,
      company: "VectorWay",
      actions: [
        "co-founder of IT-society",
        "launch startup intensive",
        "performed as speeker on events we organized",
        "engaged in e2e organizations of events/competitions/courses we did",
        "peak of activty ~400 user in social network, ~120 people on event",
        "https://www.youtube.com/c/VectorWay",
        "https://vk.com/itvectorsoc"
      ],
      period: "September 2016 – September 2018",
      location: "Saint-Petersburg, Russia",
    },
  ],
  skills: [
    "React.js",
    "Node.js",
    "Next.js",
    "Nest.js",
    "GraphQL",
    "Redux",
    "Typescript",
    "Python",
    "BEM",
    "Flutter",
    "Presentation",
    "A/B testing",
    "Code Quality",
    "Design Patterns",
    "Amazon Web Services",
    "Task Decomposition",
    "Technical Writing",
  ],
  educationList: [
    `Saint Petersburg Electrotechnical University "LETI" — bachelor "Control in Technical Systems", 2019`,
    `Saint Petersburg Electrotechnical University "LETI" — master "Control in Technical Systems", 2021`,
  ],
  achievements: [
    {
      resource: "Amazon Web Services",
      name: "AWS Certified Developer",
      iconType: IconTypes.aws,
      link: awsCertifiedDeveloperCertSrc,
    },
    {
      resource: "Stepik",
      name: "Программирование на Python",
      iconType: IconTypes.stepik,
      link:
        "https://stepik.org/certificate/199fdc641b96d293529ef007163e2288232e9f24.pdf",
    },
    {
      resource: "Wesbos",
      name: "Learn Node — Master Package",
      iconType: IconTypes.wesbos,
      link: learnNodeCertSrc,
    },
    {
      resource: "Wesbos",
      name: "React For Beginners — Master Package",
      iconType: IconTypes.wesbos,
      link: reactForBeginnerCertSrc,
    },
  ],
};
