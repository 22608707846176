import React from "react";
import { cn } from "@bem-react/classname";

import gridDynamicsLogoSrc from '../../../../public/assets/images/companies/grid-dynamics.png'

import './GridDynamicsIcon.scss';

export const cnGridDynamicsIcon = cn("GridDynamicsIcon");

export const GridDynamicsIcon = ({ className }) => {
  return (
      <div className={cnGridDynamicsIcon(null, [className])}>
          {/* <img src={gridDynamicsLogoSrc} alt="grid dynamics" /> */}
      </div>
  );
};
