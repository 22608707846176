import React from "react";
import { cn } from "@bem-react/classname";
import "./FreelanceIcon.scss";

export const cnFreelanceIcon = cn("FreelanceIcon");

export const FreelanceIcon = ({ className }) => {
  return (
    <div className={cnFreelanceIcon(null, [className])} />
  );
};
