import React from "react";
import { cn } from "@bem-react/classname";
import { StepikIcon } from "../Icons/StepikIcon";
import { WesBosIcon } from "../Icons/WesBosIcon";

import { Card } from "../Card/Card";
import { data, IconTypes } from "../../../data";
import { CopyWrapper } from "../CopyWrapper/CopyWrapper";
import { AwsIcon } from "../Icons/AwsIcon";

import "./Achievements.scss";

export const cnAchievements = cn("Achievements");

export const Achievements = ({ achievements }) => {
  return (
    <div className={cnAchievements()}>
      <h3 className={cnAchievements('Title')}>Certificates</h3>

      <div className={cnAchievements('List')}>
        {achievements.map((achievement, i) => (
          <Card key={`achievement-${i}`} className={cnAchievements("Item")}>
            <CopyWrapper>
              <div className={cnAchievements("ItemContent")}>
                {achievement.iconType === IconTypes.stepik && (
                  <StepikIcon className={cnAchievements("Icon")} />
                )}
                {achievement.iconType === IconTypes.wesbos && (
                  <WesBosIcon className={cnAchievements("Icon")} />
                )}
                {achievement.iconType === IconTypes.aws && (
                  <AwsIcon className={cnAchievements("Icon")} />
                )}
                <span className={cnAchievements("ItemName")}>
                  {achievement.resource}
                </span>
              </div>
              <a
                className={cnAchievements("ItemLink")}
                href={achievement.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={cnAchievements("ItemName")}>
                  {achievement.name}
                </span>
              </a>
            </CopyWrapper>
          </Card>
        ))}
      </div>
    </div>
  );
};
