import React from "react";
import { cn } from "@bem-react/classname";

export const cnAwsIcon = cn("AwsIcon");

export const AwsIcon = ({ className }) => {
  return (
    <svg
      className={cnAwsIcon(null, [className])}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="452.000000pt"
      height="452.000000pt"
      viewBox="0 0 452.000000 452.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,452.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M645 3480 c-144 -15 -341 -85 -365 -129 -5 -11 -10 -51 -10 -89 0
-103 4 -104 153 -54 206 69 351 78 463 28 92 -42 123 -105 131 -270 l6 -109
-34 7 c-95 19 -232 36 -299 36 -336 -1 -547 -215 -501 -508 15 -102 50 -170
116 -231 85 -77 142 -95 300 -95 112 0 138 3 190 24 74 29 149 75 204 128 l42
40 21 -42 c11 -22 36 -60 55 -83 47 -58 76 -58 166 2 80 54 83 68 35 171 l-32
69 -7 360 c-4 231 -11 380 -19 415 -29 126 -100 224 -199 275 -96 48 -262 70
-416 55z m314 -791 l64 -12 -5 -91 c-6 -102 -21 -145 -68 -200 -111 -126 -354
-156 -447 -55 -32 35 -43 72 -43 146 0 117 53 187 168 220 54 15 228 11 331
-8z"
        />
        <path
          d="M3750 3484 c-175 -32 -300 -116 -362 -242 -29 -58 -33 -76 -33 -147
0 -96 20 -160 73 -230 58 -78 137 -125 292 -173 197 -62 268 -91 306 -124 102
-90 48 -239 -102 -281 -120 -34 -297 -17 -453 43 -53 21 -91 30 -107 26 -23
-6 -24 -10 -24 -87 0 -78 1 -82 31 -110 60 -56 251 -103 424 -103 133 0 208
15 312 64 224 104 302 397 156 588 -47 63 -130 106 -333 172 -111 37 -210 76
-237 94 -61 41 -87 91 -79 151 9 54 35 89 89 118 38 20 56 22 182 22 133 0
144 -2 232 -33 51 -19 103 -32 115 -30 21 3 23 8 26 82 2 64 0 82 -15 98 -49
54 -197 97 -358 103 -60 2 -121 2 -135 -1z"
        />
        <path
          d="M1422 3438 c-7 -7 -12 -21 -12 -32 0 -23 354 -1206 377 -1259 21 -49
43 -57 148 -57 92 0 129 10 143 37 6 10 61 235 123 500 63 266 116 483 119
483 3 0 57 -219 120 -488 95 -401 119 -491 138 -510 22 -23 29 -24 132 -20 86
2 113 7 126 20 18 17 404 1254 404 1292 0 39 -19 46 -118 46 -103 0 -125 -7
-145 -48 -8 -15 -69 -246 -137 -514 -69 -269 -127 -488 -130 -488 -3 0 -43
161 -89 358 -147 633 -153 654 -172 673 -27 27 -216 28 -248 2 -24 -20 -22
-15 -162 -615 -51 -225 -96 -408 -99 -408 -3 0 -61 224 -130 498 -99 392 -130
502 -147 520 -20 20 -31 22 -126 22 -67 0 -107 -4 -115 -12z"
        />
      </g>
    </svg>
  );
};
