import React from "react";
import { cn } from "@bem-react/classname";
import "./HolyJSIcon.scss";

export const cnHolyJSIcon = cn("HolyJSIcon");

export const HolyJSIcon = ({ className }) => {
  return (
    <div className={cnHolyJSIcon(null, [className])} />
  );
};
