import React from 'react';
import { cn } from '@bem-react/classname';
import { CopyWrapper } from '../CopyWrapper/CopyWrapper';

import './Skills.scss';

export const cnSkills = cn('Skills');

export const Skills = ({ skills }) => (
  <div className={cnSkills()}>
    <CopyWrapper text={skills.join(', ')}>
      <div className={cnSkills('List')}>
          {skills.map((skill) => (
            <span key={skill} className={cnSkills('Item')}>
              {skill}
            </span>
          ))}
      </div>
    </CopyWrapper>
  </div>
);
