import React from "react";
import { cn } from "@bem-react/classname";
import "./VectorWayIcon.scss";

export const cnVectorWayIcon = cn("VectorWayIcon");

export const VectorWayIcon = ({ className }) => {
  return (
    <div className={cnVectorWayIcon(null, [className])} />
  );
};
