import React from "react";
import { cn } from "@bem-react/classname";
import "./YandexIcon.scss";

export const cnYandexIcon = cn("YandexIcon");

export const YandexIcon = ({ className }) => {
  return (
    <div className={cnYandexIcon(null, [className])} />
  );
};
