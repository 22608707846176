import React from "react";
import { cn } from "@bem-react/classname";
import "./UpTeamIcon.scss";

export const cnUpTeamIcon = cn("UpTeamIcon");

export const UpTeamIcon = ({ className }) => {
  return (
    <div className={cnUpTeamIcon(null, [className])} />
  );
};
