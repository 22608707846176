import React from "react";
import { cn } from "@bem-react/classname";

export const cnWesBosIcon = cn("WesBosIcon");

export const WesBosIcon = ({ className }) => {
  return (
    <svg
      className={cnWesBosIcon(null, [className])}
      fill="#FAC748"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="400.000000pt"
      height="328.000000pt"
      viewBox="0 0 400.000000 328.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,328.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2006 3158 c-8 -13 -27 -49 -42 -80 -15 -31 -31 -55 -36 -52 -4 3 -5
-2 -2 -10 3 -8 -4 -32 -15 -55 -12 -22 -21 -47 -21 -56 0 -9 -3 -14 -8 -11 -4
2 -16 -16 -26 -41 -14 -33 -21 -41 -28 -31 -7 9 -9 7 -5 -8 2 -11 -9 -45 -25
-75 -16 -30 -27 -46 -24 -36 4 11 2 16 -5 12 -5 -4 -7 -15 -4 -25 7 -23 -22
-83 -35 -75 -6 3 -7 0 -4 -8 5 -15 -70 -177 -88 -188 -5 -3 -9 -9 -9 -13 3
-18 0 -27 -7 -22 -4 2 -7 -11 -7 -31 1 -19 -4 -37 -10 -39 -6 -3 -29 -41 -50
-84 l-39 -80 -28 48 c-15 26 -28 52 -27 57 0 6 7 -2 15 -17 20 -38 28 -36 9 2
-9 17 -20 28 -24 25 -7 -4 -121 181 -180 293 -8 15 -18 30 -23 33 -5 3 -9 12
-8 20 4 28 0 40 -12 33 -8 -5 -9 -2 -5 10 4 10 3 15 -3 11 -5 -3 -17 10 -27
29 -9 20 -21 36 -25 36 -5 0 -6 6 -2 13 5 8 2 7 -9 -2 -10 -8 -15 -21 -12 -31
3 -9 3 -25 -1 -34 -5 -14 -9 -14 -23 -4 -14 12 -14 11 -3 -4 18 -23 7 -59 -16
-52 -15 5 -15 5 0 -6 13 -8 15 -15 8 -28 -16 -29 -44 -96 -50 -122 -3 -14 -11
-24 -18 -23 -6 2 -9 -3 -6 -11 3 -8 -37 -121 -89 -252 -52 -132 -102 -261
-112 -287 -13 -34 -21 -44 -27 -35 -5 8 -6 3 -2 -13 3 -15 1 -30 -5 -33 -6 -4
-11 -3 -11 2 0 5 -9 12 -20 15 -11 3 -18 1 -15 -3 3 -5 1 -10 -5 -12 -13 -4
-32 -68 -19 -68 5 0 9 -4 9 -10 0 -9 -547 404 -585 443 -11 11 17 -8 63 -42
45 -34 82 -60 82 -57 0 3 -35 32 -78 65 -51 38 -83 56 -91 51 -14 -8 -5 -72
35 -277 8 -40 13 -76 12 -80 0 -5 2 -15 5 -23 3 -8 22 -109 42 -225 20 -115
40 -222 45 -237 5 -17 5 -30 -1 -33 -5 -4 -9 -1 -9 6 0 7 -3 9 -7 5 -5 -4 -1
-13 8 -19 10 -8 23 -54 38 -137 33 -188 30 -180 48 -173 11 4 9 -1 -5 -15 -12
-12 -22 -32 -21 -44 1 -22 1 -22 8 2 5 18 13 -11 30 -106 19 -105 21 -135 12
-155 -11 -23 -11 -23 3 -5 9 11 16 14 16 8 0 -7 8 -12 18 -13 10 0 12 -3 5 -6
-7 -2 -13 -10 -13 -16 0 -6 -7 -18 -14 -26 -10 -11 -12 -21 -6 -31 8 -12 10
-11 10 7 0 25 15 39 25 23 3 -5 1 -10 -5 -10 -6 0 -8 -16 -5 -42 4 -34 3 -39
-5 -23 -9 18 -10 17 -5 -5 2 -14 5 -36 6 -50 1 -23 2 -23 8 5 7 27 8 26 15
-23 8 -51 9 -53 35 -48 30 6 1192 156 1209 156 7 0 11 -3 11 -7 -4 -23 3 -25
19 -6 25 32 280 63 405 49 69 -7 87 -13 87 -25 0 -13 2 -13 9 -3 7 12 69 6
350 -30 215 -28 341 -40 341 -33 0 6 3 6 8 -1 8 -13 336 -59 358 -50 8 3 14 1
14 -4 0 -6 14 -10 30 -10 19 0 30 5 30 14 0 7 7 19 15 26 18 15 20 -1 3 -19
-10 -11 -10 -13 0 -7 7 4 12 1 12 -7 0 -11 18 -17 65 -22 93 -10 121 -19 117
-38 -2 -10 2 -17 7 -17 6 0 8 -7 5 -15 -10 -26 22 -17 34 10 6 14 8 25 3 25
-5 0 -11 -6 -13 -12 -3 -7 -7 -3 -10 8 -4 17 -2 20 13 17 13 -4 20 2 25 21 3
14 10 26 15 26 5 0 6 -8 3 -18 -5 -15 -4 -15 6 1 6 10 9 25 6 32 -3 8 -1 17 4
20 6 4 10 12 10 18 0 8 -3 8 -9 -2 -9 -14 -19 -4 -15 17 1 6 2 20 4 30 1 10 3
12 7 5 3 -10 8 -10 19 1 15 16 9 46 -10 46 -6 0 -4 5 4 10 13 9 13 11 0 20
-13 9 -7 14 13 11 11 -2 8 17 -4 24 -6 4 -8 13 -5 21 7 17 -14 30 -23 15 -8
-13 -41 -4 -41 10 0 5 9 9 20 9 11 0 20 5 20 10 0 6 -6 10 -12 10 -10 0 -9 3
1 9 8 5 10 14 6 21 -8 13 7 12 48 -5 10 -4 17 -3 17 4 0 6 -4 11 -9 11 -8 0
-7 23 5 90 2 14 36 198 74 410 38 212 71 404 73 427 2 24 6 46 10 50 4 3 7 17
7 31 0 14 8 31 18 39 9 8 11 12 5 8 -9 -4 -11 5 -7 37 6 42 23 86 24 61 0 -18
17 -16 24 3 3 8 2 12 -4 9 -6 -3 -10 2 -10 12 0 15 -2 16 -10 3 -13 -20 -13
-18 5 73 8 43 15 82 16 85 0 4 4 -2 9 -13 8 -18 9 -17 9 8 1 16 -3 26 -9 22
-5 -3 -10 1 -10 9 0 20 17 30 23 14 4 -9 6 -10 6 -1 2 22 -16 23 -42 3 -27
-22 -42 -26 -32 -10 10 16 -1 11 -16 -7 -12 -15 -12 -16 1 -8 13 8 13 7 1 -8
-8 -10 -19 -16 -25 -14 -6 2 -15 -5 -21 -16 -5 -11 -17 -23 -25 -25 -13 -5
-13 -3 0 14 14 18 14 18 -2 5 -10 -7 -16 -17 -13 -21 7 -11 -35 -41 -47 -34
-5 3 -7 0 -6 -7 2 -6 -3 -14 -11 -17 -9 -3 -11 0 -6 8 6 9 4 11 -4 6 -7 -5
-10 -11 -7 -14 7 -7 -13 -32 -26 -32 -5 0 -6 6 -2 13 5 9 3 9 -7 -1 -8 -7 -15
-16 -15 -20 -5 -22 -17 -52 -23 -53 -28 -3 -45 -11 -75 -34 -29 -22 -36 -24
-36 -11 0 9 -5 16 -11 16 -5 0 -7 4 -4 10 3 6 -4 9 -19 8 -25 -2 -25 -9 2 -41
14 -16 14 -19 0 -33 -15 -15 -17 -14 -23 5 -3 11 -4 25 0 30 9 15 -16 24 -28
11 -9 -10 -9 -11 0 -6 9 5 11 2 7 -8 -3 -9 -10 -13 -15 -10 -5 3 -9 1 -9 -5 0
-5 -11 -8 -24 -5 -14 2 -28 -1 -32 -7 -5 -8 -3 -9 7 -4 12 8 12 6 0 -8 -16
-20 -12 -27 17 -30 11 0 19 -7 18 -15 -3 -15 -116 -107 -116 -93 0 5 4 11 9
13 5 2 3 14 -5 28 l-15 25 6 -25 c3 -14 -3 -8 -13 15 -18 38 -18 41 -2 55 19
17 16 40 -5 40 -8 0 -17 9 -20 20 -3 11 -10 20 -15 20 -5 0 -14 10 -21 21 -8
15 -8 19 0 15 6 -4 11 0 12 11 1 10 2 32 4 48 1 24 -2 31 -17 32 -10 1 -18 -2
-18 -8 0 -5 6 -9 13 -9 8 0 7 -4 -3 -10 -12 -8 -13 -11 -1 -19 8 -5 9 -11 3
-15 -6 -3 -14 4 -17 16 -4 13 -18 47 -32 76 -20 43 -23 57 -14 80 11 25 11 26
6 2 -2 -14 0 -31 4 -38 5 -8 7 2 6 25 -3 54 -3 53 -29 53 -17 0 -26 7 -30 25
-4 14 -4 30 -1 35 3 4 0 19 -5 32 -10 21 -10 20 -5 -7 l5 -30 -16 28 c-8 16
-21 47 -28 70 -8 23 -17 51 -20 62 -5 14 -2 12 8 -5 l16 -25 -7 30 c-3 16 -6
35 -6 42 0 7 -4 10 -8 7 -4 -2 -10 2 -13 8 -3 7 -3 2 0 -12 4 -22 4 -23 -6 -7
-7 10 -9 25 -6 33 3 8 2 13 -3 10 -5 -3 -16 15 -26 39 -9 25 -21 45 -26 45 -5
0 -4 12 2 26 5 15 7 29 4 32 -3 3 -5 -1 -5 -9 -1 -8 -7 -3 -15 11 -11 19 -14
21 -15 8 0 -10 -7 -18 -16 -18 -8 0 -13 4 -9 9 3 5 1 12 -4 15 -6 4 -4 17 4
32 18 36 0 76 -20 44 -4 -6 -10 -8 -15 -5 -4 3 -7 -3 -5 -12 2 -19 -27 -69
-54 -91 -14 -11 -14 -14 -1 -22 11 -7 11 -10 2 -10 -7 0 -20 -16 -28 -35 -8
-20 -20 -38 -27 -41 -7 -2 -12 -19 -11 -38 1 -26 -2 -33 -12 -29 -10 4 -12 0
-8 -11 4 -10 1 -16 -9 -16 -10 0 -14 -8 -12 -25 1 -18 -2 -24 -13 -23 -13 3
-38 -33 -87 -127 -24 -47 -44 -69 -52 -60 -5 4 -2 10 5 12 19 7 55 84 44 95
-13 13 -42 -1 -42 -20 0 -12 3 -13 12 -4 22 22 29 12 13 -18 -9 -16 -22 -30
-30 -30 -20 0 -57 60 -51 83 2 9 1 15 -3 13 -12 -8 -174 330 -166 345 5 7 4
10 -2 6 -5 -3 -26 28 -46 69 -41 82 -43 89 -24 78 7 -4 9 -3 4 2 -9 9 -13 10
-43 13 -12 1 -15 7 -11 22 3 13 -3 39 -14 61 -11 22 -18 46 -15 54 3 8 2 13
-2 10 -5 -3 -24 27 -43 65 -18 39 -39 75 -46 81 -11 9 -10 14 3 29 10 11 12
19 6 19 -7 0 -12 -6 -12 -12 -1 -7 -8 -1 -16 15 -18 33 -29 34 -48 5z m34
-744 c0 -18 121 -195 129 -190 4 3 7 -3 8 -12 1 -9 8 -38 15 -64 9 -35 18 -48
30 -48 24 0 23 -21 -2 -27 -12 -3 -20 -14 -20 -27 0 -33 -47 -94 -88 -115 -59
-30 -119 -29 -168 1 -72 46 -101 115 -82 201 9 44 28 77 121 211 15 22 25 46
22 53 -3 9 0 11 7 7 7 -5 9 -2 6 8 -4 9 0 15 8 15 8 0 14 -6 14 -13z m-1354
-890 c29 -80 77 -263 112 -424 l10 -45 1 69 c2 114 39 226 75 226 12 0 12 1
78 -131 45 -91 47 -93 46 -54 0 62 -11 91 -33 87 -16 -3 -17 -2 -5 12 7 9 14
43 16 76 3 48 1 60 -11 60 -8 0 -17 10 -20 23 -8 29 17 122 38 147 17 20 37
26 37 13 1 -5 5 -28 11 -53 8 -39 29 -249 31 -320 3 -82 -10 -220 -21 -234
-16 -20 -41 -21 -41 -2 0 7 -6 19 -13 25 -8 6 -20 30 -28 53 -21 66 -68 156
-69 133 -2 -173 -14 -377 -26 -463 -11 -78 -70 -82 -101 -7 -10 25 -23 45 -30
45 -19 0 -28 42 -10 51 12 7 13 17 7 46 -5 21 -11 63 -15 93 -3 30 -10 73 -16
95 -6 22 -13 54 -16 70 -3 17 -12 35 -21 42 -11 9 -12 16 -4 26 7 8 9 35 5 71
-5 46 -10 59 -27 66 -12 5 -15 9 -8 9 15 1 17 61 1 61 -5 0 -8 4 -5 8 7 12
-20 141 -44 205 -19 50 -20 61 -9 86 l13 29 29 -50 c16 -28 44 -92 63 -144z
m1842 59 c38 -12 57 -24 63 -40 5 -13 16 -26 24 -29 56 -22 29 -100 -53 -157
-29 -20 -62 -37 -72 -37 -11 0 -29 -11 -42 -24 -13 -13 -32 -26 -43 -29 -11
-3 5 -3 37 0 73 7 148 -4 155 -23 9 -25 -18 -64 -77 -111 -77 -61 -110 -94
-107 -107 1 -6 -8 -11 -19 -10 -12 0 -20 -4 -18 -10 4 -10 -39 -36 -59 -36
-19 0 -97 -62 -97 -77 0 -9 -3 -13 -6 -10 -4 3 -16 0 -28 -8 -11 -8 -30 -15
-41 -15 -19 0 -65 -27 -133 -76 -23 -17 -23 -17 -11 9 7 15 16 27 21 27 4 0 8
5 8 11 0 6 -7 9 -15 5 -22 -8 -18 2 6 15 15 8 19 8 15 0 -12 -20 13 -12 33 11
11 11 17 26 14 32 -11 18 -2 87 19 143 20 54 46 86 34 42 -6 -20 -5 -21 4 -7
6 10 10 24 9 30 -1 7 10 39 24 71 15 32 27 63 27 69 0 6 13 32 28 57 35 58 57
110 48 118 -3 3 -6 -1 -6 -10 0 -8 -16 -25 -35 -37 -19 -12 -35 -25 -35 -30 0
-17 -59 15 -69 37 -6 13 -7 23 -3 23 5 0 21 13 38 28 49 47 119 91 198 122 41
17 79 30 85 30 6 0 11 5 11 10 0 13 4 13 68 -7z m-938 -18 c-8 -10 -9 -15 -1
-15 35 0 -29 -112 -71 -126 -12 -4 -30 -16 -40 -28 -20 -24 -23 -13 -7 33 6
18 9 34 6 37 -2 3 -22 -7 -43 -22 -22 -14 -49 -30 -60 -34 -16 -6 -23 -21 -29
-57 -4 -26 -11 -53 -17 -60 -27 -33 4 -27 63 12 36 23 77 46 90 50 23 7 23 7
7 -12 -10 -11 -18 -24 -18 -29 -1 -15 -42 -58 -85 -90 -22 -16 -55 -44 -74
-61 -19 -18 -38 -33 -42 -33 -4 0 -10 -13 -14 -30 -4 -16 -12 -30 -19 -30 -6
0 -17 -18 -23 -40 l-10 -40 36 33 c108 97 321 202 321 157 0 -5 -6 -10 -13
-10 -47 0 -295 -204 -322 -264 -7 -17 -14 -25 -14 -17 -1 8 -10 3 -22 -13 -28
-36 -64 -49 -111 -41 -48 8 -48 19 0 44 61 31 79 58 91 138 6 39 14 78 17 86
4 11 -5 21 -25 32 -37 18 -40 35 -8 35 40 0 56 22 82 110 32 110 32 110 -9
103 -21 -3 -36 -1 -41 7 -10 16 8 26 56 34 22 4 39 12 39 19 0 7 7 22 15 33
15 19 15 19 30 -1 15 -19 19 -18 143 47 70 36 129 64 131 62 2 -2 -2 -10 -9
-19z m178 -45 c36 -22 52 -54 52 -103 0 -40 -20 -99 -40 -118 -20 -19 -56 -18
-51 1 2 8 10 38 17 65 27 98 11 141 -33 89 -45 -53 -83 -118 -83 -140 0 -32
40 -75 114 -124 63 -40 106 -98 106 -141 0 -18 -34 -49 -75 -69 -28 -14 -78
-48 -152 -106 -18 -13 -36 -25 -40 -25 -5 0 -18 -10 -30 -21 -13 -12 -25 -19
-28 -16 -5 5 153 170 199 207 24 19 27 28 22 54 -3 18 -18 42 -33 55 -16 13
-49 41 -76 63 -105 89 -101 164 16 301 47 53 66 58 115 28z m1329 -108 c13
-36 14 -50 5 -77 -7 -18 -14 -54 -16 -81 -2 -27 -16 -74 -30 -104 -14 -30 -26
-60 -26 -67 0 -6 -3 -13 -7 -15 -5 -1 -20 -29 -35 -60 -16 -33 -34 -58 -43
-58 -8 0 -15 -4 -15 -9 0 -5 -16 -25 -37 -44 -27 -27 -40 -34 -53 -27 -12 6
-27 2 -54 -16 -20 -14 -52 -28 -71 -31 -27 -4 -35 -1 -45 17 -6 12 -21 31 -32
42 -39 39 -58 109 -39 146 14 27 29 102 20 96 -14 -8 -12 -1 44 119 60 129 84
170 97 162 5 -3 11 3 15 14 9 30 68 71 86 60 11 -5 28 0 54 18 68 46 85 46
128 -1 20 -22 45 -61 54 -84z m344 27 c11 -5 17 -14 15 -18 -3 -4 5 -18 19
-31 14 -13 21 -28 18 -38 -4 -10 -2 -13 5 -8 6 3 13 2 16 -3 12 -19 -15 -22
-40 -6 -20 13 -37 15 -75 10 -28 -4 -47 -12 -46 -18 1 -7 -10 -13 -24 -15 -36
-4 -62 -35 -65 -76 -3 -32 0 -35 34 -45 l37 -11 -38 5 c-59 6 -31 -18 45 -39
67 -19 86 -27 133 -54 39 -22 56 -43 49 -62 -3 -8 -2 -11 2 -7 4 4 15 -5 25
-19 15 -24 16 -30 3 -68 -18 -50 -60 -100 -76 -90 -7 5 -8 3 -3 -6 5 -8 3 -11
-6 -8 -15 6 -69 -21 -69 -35 0 -4 -4 -6 -9 -3 -5 4 -30 -3 -56 -14 -57 -24
-100 -27 -91 -5 3 9 0 15 -10 15 -9 0 -12 -5 -8 -12 5 -8 2 -9 -10 -5 -10 4
-22 7 -27 7 -15 0 -89 71 -89 86 0 8 7 17 15 20 12 5 12 9 -1 30 -21 31 -35
93 -27 122 4 18 11 22 32 20 22 -3 27 -9 31 -43 3 -22 10 -47 15 -55 5 -8 10
-22 12 -30 3 -20 63 -70 85 -70 10 0 18 -4 18 -9 0 -16 72 7 94 29 l21 22 -22
-11 c-12 -6 -28 -11 -35 -11 -7 1 6 10 30 22 31 15 42 26 42 43 0 41 -45 81
-129 114 -93 38 -130 73 -138 129 -4 32 0 47 21 78 14 21 26 44 26 50 0 11 23
35 104 108 23 21 39 26 78 26 27 0 58 -5 69 -11z"
        />
        <path
          d="M2397 1477 c-38 -9 -49 -31 -26 -54 7 -7 7 -13 0 -17 -5 -4 -8 -13
-4 -21 3 -9 1 -21 -5 -28 -5 -6 -15 -30 -21 -52 -10 -39 -9 -38 91 62 56 56
99 106 94 110 -10 11 -84 11 -129 0z"
        />
        <path
          d="M2349 1175 c-25 -13 -60 -40 -77 -59 -17 -20 -35 -36 -40 -36 -7 0
-52 -82 -52 -96 0 -4 12 1 26 11 14 9 28 14 31 12 3 -3 -8 -12 -24 -21 -33
-17 -61 -46 -45 -46 6 0 14 5 17 10 3 6 13 10 21 10 8 0 37 23 64 51 27 28 42
48 34 45 -8 -3 4 12 27 34 23 22 46 37 52 33 6 -3 7 -1 3 5 -4 6 0 25 9 42 19
36 14 36 -46 5z"
        />
        <path
          d="M2921 1427 c-6 -8 -8 -16 -5 -20 10 -9 -16 -47 -32 -47 -8 0 -13 -4
-10 -9 3 -5 -14 -31 -39 -57 -25 -27 -45 -52 -45 -56 0 -3 -14 -20 -30 -37
-23 -23 -29 -36 -25 -54 5 -19 2 -25 -14 -30 -28 -7 -41 -46 -41 -121 0 -61 1
-64 29 -76 43 -17 64 -10 73 28 5 18 15 35 23 39 8 3 15 9 15 15 0 5 20 29 45
55 43 43 55 63 37 63 -5 0 -9 13 -9 29 0 27 2 29 21 19 13 -7 16 -13 8 -16 -6
-2 -12 -8 -12 -13 0 -5 9 -4 20 3 21 14 36 44 31 66 -1 8 2 19 7 25 5 7 12 25
16 41 4 15 10 26 13 23 3 -3 0 -21 -6 -39 -11 -32 -11 -32 6 -15 13 13 19 38
21 95 4 76 3 78 -23 90 -34 16 -60 15 -74 -1z"
        />
      </g>
    </svg>
  );
};
