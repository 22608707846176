import React from 'react';
import { cn } from '@bem-react/classname';
import { CopyWrapper } from '../CopyWrapper/CopyWrapper';
import { Card } from '../Card/Card';

import './Summary.scss';

export const cnSummary = cn('Summary');

export const Summary = ({ content }) => {
  return (
    <div className={cnSummary()}>
      <h3 className={cnSummary('Title')}>Summary</h3>
      <Card className={cnSummary('Content')}>
        <CopyWrapper text={content}>
          {content}
        </CopyWrapper>
      </Card>
    </div>
  );
};
