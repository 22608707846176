import React from "react";
import { cn } from "@bem-react/classname";

export const cnStepikIcon = cn("StepikIcon");

export const StepikIcon = ({ className }) => {
  return (
    <svg
      className={cnStepikIcon(null, [className])}
      fill="#7DDE92"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id="Layer_1"
      x="0px"
      y="0px"
      width="250px"
      height="250px"
      viewBox="0 0 250 250"
      xmlSpace="preserve"
    >
      {" "}
      <g>
        {" "}
        <path d="M205.3,77.5l-32.9-32.9C159.8,32,142.9,25,125,25c-17.9,0-34.8,7-47.5,19.7L44.7,77.5C32,90.2,25,107.1,25,125 c0,17.9,7,34.8,19.7,47.5l32.9,32.9C90.2,218,107.1,225,125,225c17.4,0,34-6.7,46.6-18.8l0.1-0.1l0.1-0.1l33.6-33.6 C218,159.8,225,142.9,225,125S218,90.2,205.3,77.5z"></path>{" "}
        <path
          fill="#FFFFFF"
          d="M149,182.9c-6.2,6-14.7,9.7-24.1,9.7c-9.6,0-18.2-3.9-24.5-10.2l16.2-16.2c2.1,2.1,5.1,3.5,8.3,3.5 c3.3,0,6.2-1.3,8.3-3.5l32.9-32.9c2.1-2.1,3.5-5.1,3.5-8.3c0-3.3-1.3-6.2-3.5-8.3c-2.1-2.1-5.1-3.5-8.3-3.5c-3.3,0-6.2,1.3-8.3,3.5 l-32.9,32.9c-6.3,6.3-15,10.2-24.5,10.2c-9.6,0-18.2-3.9-24.5-10.2c-6.3-6.3-10.2-15-10.2-24.5c0-9.6,3.9-18.2,10.2-24.5l32.9-32.9 c6.3-6.3,15-10.2,24.5-10.2c9.6,0,18.2,3.9,24.5,10.2l-16.2,16.2c-2.1-2.1-5.1-3.5-8.3-3.5c-3.3,0-6.2,1.3-8.3,3.5l-32.9,32.9 c-2.1,2.1-3.5,5.1-3.5,8.3c0,3.3,1.3,6.2,3.5,8.3c2.1,2.1,5.1,3.5,8.3,3.5c3.3,0,6.2-1.3,8.3-3.5l32.9-32.9 c6.3-6.3,15-10.2,24.5-10.2s18.2,3.9,24.5,10.2c6.3,6.3,10.2,15,10.2,24.5c0,9.6-3.9,18.2-10.2,24.5L149,182.9z"
        ></path>{" "}
      </g>{" "}
    </svg>
  );
};
