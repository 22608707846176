import React from "react";
import { cn } from "@bem-react/classname";
import "./EyeHelperIcon.scss";

export const cnEyeHelperIcon = cn("EyeHelperIcon");

export const EyeHelperIcon = ({ className }) => {
  return (
    <div className={cnEyeHelperIcon(null, [className])} />
  );
};
