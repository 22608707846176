import React from 'react';
import { cn } from '@bem-react/classname';
import { CopyWrapper } from '../CopyWrapper/CopyWrapper';
import { Card } from '../Card/Card';
import { IconTypes } from '../../../data';
import { GridDynamicsIcon } from '../Icons/GridDynamicsIcon';
import { YandexIcon } from '../Icons/YandexIcon';
import { UpTeamIcon } from '../Icons/UpTeamIcon';
import { FreelanceIcon } from '../Icons/FreelanceIcon';
import { EyeHelperIcon } from '../Icons/EyeHelperIcon';
import { CloZIcon } from '../Icons/CloZIcon';
import { VectorWayIcon } from '../Icons/VectorWayIcon';
import { HolyJSIcon } from '../Icons/HolyJSIcon';
import './Experience.scss';

export const cnExperience = cn('Experience');

export const Experience = ({ experiences, title = 'Work Experience' }) => (
  <div className={cnExperience()}>
    <h3 className={cnExperience('Title')}>{title}</h3>

    <div className={cnExperience('List')}>
      {experiences.map((exp, i) => (
        <Card key={exp.company + i} className={cnExperience('Item')}>
          <div className={cnExperience('ItemTitleWrapper')}>
            {exp.iconType === IconTypes.cloz && <CloZIcon />}
            {exp.iconType === IconTypes.eyehelper && <EyeHelperIcon />}
            {exp.iconType === IconTypes.freelance && <FreelanceIcon />}
            {exp.iconType === IconTypes.griddynamics && <GridDynamicsIcon />}
            {exp.iconType === IconTypes.holyjs && <HolyJSIcon />}
            {exp.iconType === IconTypes.vectorway && <VectorWayIcon />}
            {exp.iconType === IconTypes.upteam && <UpTeamIcon />}
            {exp.iconType === IconTypes.yandex && <YandexIcon />}
            <h3 className={cnExperience('ItemTitle')}>{exp.company} </h3>
          </div>
          <p className={cnExperience('ItemSubtitle')}>{exp.period}</p>
          <CopyWrapper text={exp.actions.join('\n')}>
            <ul className={cnExperience('ItemText')}>
              {exp.actions.map((action, i) => <li key={`${exp.company}-summary-${i}`}>{action}</li>)}
            </ul>
          </CopyWrapper>
        </Card>
      ))}
    </div>

  </div>
);