import React from 'react';
import { cn } from '@bem-react/classname';
import { Avatar } from '../Avatar/Avatar';
import { Summary } from '../Summary/Summary';
import { Experience } from '../Experience/Experience';
import { Skills } from '../Skills/Skills';
import { Achievements } from '../Achievements/Achievements';
import { Socials } from '../Socials/Socials';
import { data } from '../../../data';
import { Education } from '../Education/Education';

import './App.scss';

const cnApp = cn('App');

export const App = () => {
  return (
    <div className={cnApp()}>
      <div className={cnApp('Content')}>
        <Avatar
          email={data.contacts.email}
          location={data.contacts.location}
          phone={data.contacts.phone}
          profession={data.contacts.profession}
          username={data.contacts.username}
        />
        <Summary content={data.summary} />
        <Skills skills={data.skills} />
        <Experience experiences={data.experience} />
        <Experience title='Side Activities' experiences={data.sideActivities} />
        <Achievements achievements={data.achievements} />
        <Education educationList={data.educationList} />
        <Socials />
      </div>
    </div>
  );
};
