import React from 'react';
import { cn } from '@bem-react/classname';
import { Card } from '../Card/Card';

import './Education.scss';
import { CopyWrapper } from '../CopyWrapper/CopyWrapper';

export const cnEducation = cn('Education');

export const Education = ({ educationList }) => (
    <div className={cnEducation()}>
        <h3 className={cnEducation('Title')}>Education</h3>

        <div className={cnEducation('List')}>
            {educationList.map((item, i) => (
                <Card key={`education-${i}`} className={cnEducation('Item')}>
                    <p className={cnEducation('ItemTitle')}>
                        <CopyWrapper text={item}>{item}</CopyWrapper>
                    </p>
                </Card>
            ))}
        </div>
    </div>
);
