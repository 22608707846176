import React from 'react';
import { cn } from '@bem-react/classname';

import './Card.scss';

export const cnCard = cn('Card');

export const Card = ({ children, className }) => (
  <div className={cnCard(null, [className])}>
    {children}
  </div>
);
